<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="header_name">
                    {{ locale_message.name_charitable_organization }}
                </h1>
            </v-col>
<!--          <v-col>-->
<!--            <v-alert-->
<!--                color="secondary"-->
<!--                width="100"-->
<!--                dark="dark"-->
<!--            >TESTING</v-alert>-->
<!--          </v-col>-->
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "HeaderName",
    computed: {
        ...mapGetters({
            locale_message: "lang_messages"
        })
    }
}
</script>

<style scoped lang="scss">
.header_name {
    font-size: 1.5rem;
    font-family: Appetite;
    text-align: left;
    color: #e91e63;

    @media screen and (max-width: 767px) {
        text-align: center;
    }
}
</style>
