<template>
    <div>
        <div>
            <v-bottom-navigation
                class="disable-box-shadow"
            >
                <HeaderName class="d-none d-sm-flex"/>
                <v-btn
                    :href="'/ua/' + $route.params.page"
                >
                    UA
                </v-btn>
                <v-btn
                    :href="'/en/' + $route.params.page"
                >
                    EN
                </v-btn>
<!--                <v-btn-->
<!--                    :href="'/pl/' + $route.params.page"-->
<!--                >-->
<!--                    PL-->
<!--                </v-btn>-->
                <v-btn
                    :href="'/ru/' + $route.params.page"
                >
                    RU
                </v-btn>
            </v-bottom-navigation>
            <HeaderName class="d-flex d-sm-none"/>
        </div>
        <v-alert
            :width="'100%'"
            color="primary"
            dense
            dark
            class="d-flex justify-center align-content-center"
        >
            <v-row class="pa-md-5">
                <v-col
                    offset-md="1"
                    offset-lg="2"
                    cols="12"
                    md="10"
                    lg="8"
                >
                    <h2 class="header_desc">
                        {{ locale_message.header_title }}
                    </h2>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import HeaderName     from "./HeaderName";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Header",
    components: {HeaderName},
    data() {
        return {
            show: false
        }
    },
    computed: {
        ...mapGetters({
            locale_message: "lang_messages"
        })
    },
    methods: {
        ...mapActions({
            fetchLangMessages: 'fetchLangMessages'
        }),
    }
}
</script>

<style scoped lang="scss">

.header_desc {
    text-align: center;
    font-size: 2rem;
    @media screen and (max-width: 767px){
        font-size: 1.5rem;
    }
}

</style>
