<template>
    <!-- Sizes your content based upon application components -->
    <v-main
        class="background-color"
    >

        <!-- Provides the application the proper gutter -->
        <v-container fluid>

            <!-- If using vue-router -->
            <router-view></router-view>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "Main",
    computed: {
        ...mapGetters({
            locale: 'locale',
        })
    },
    created() {
        console.log(this.$route)
        this.SET_LOCALE(this.$route.params.locale.toString().toLowerCase());
        this.SET_PAGE(this.$route.params.page.toString().toLowerCase())
        this.fetchLangMessages(this.locale)
    },
    beforeRouteUpdate(to, from, next) {
        this.SET_LOCALE(to.params.locale.toString().toLowerCase());
        this.SET_PAGE(to.params.page.toString().toLowerCase())
        this.fetchLangMessages(this.locale)
        next()
    },
    methods: {
        ...mapMutations({
            SET_LOCALE: 'SET_LOCALE',
            SET_PAGE: 'SET_PAGE',
        }),
        ...mapActions({
            fetchLangMessages: 'fetchLangMessages',
        })
    }
}
</script>

<style scoped>

.background-color {
    background-color: #f3eded;
}

</style>
