<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mt-4">
                    <v-btn
                        large
                        block
                        color="error"
                        @click="dialog = !dialog"
                        width="100"
                    >
                        <strong style="font-size: large; font-weight: 900; letter-spacing: 5px">{{ locale_message.support }}</strong>
                    </v-btn>
                    <!--                    <v-img src="/res/img/secure.png"></v-img>-->
                    <v-card-text>
                        <v-icon>mdi-information-outline</v-icon>
                        {{ locale_message.donate_pay_info }}
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="5"
                lg="4"
            >
                <v-card>
                    <v-card-title>
                        {{ locale_message.owner_name }}
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-title>
                        <v-icon>mdi-doctor</v-icon>
                        {{ locale_message.diagnosis }}:
                    </v-card-title>
                    <v-card-subtitle>{{ locale_message.sma }}</v-card-subtitle>

                    <v-card-title>
                        <v-icon>mdi-bullseye</v-icon>
                        {{ locale_message.purpose_of_the_collection }}:
                    </v-card-title>
                    <v-card-subtitle>{{ locale_message.collection_zolgensma }}</v-card-subtitle>

                    <v-card-title>
                        <v-icon>mdi-currency-usd</v-icon>
                        {{ locale_message.сollection_amount }}:
                    </v-card-title>
                    <v-card-subtitle>
                        <strong class="found_amount_price">{{ locale_message.all_total }}</strong>
                    </v-card-subtitle>

                    <v-card-title>
                        <v-icon>mdi-currency-usd-off</v-icon>
                        {{ locale_message.already_collected }}:
                    </v-card-title>
                    <v-card-subtitle>
                        <strong class="amount_price">{{ locale_message.total }}</strong>
                    </v-card-subtitle>

                    <v-card-title>
                        <v-icon>mdi-map-marker</v-icon>
                        {{ locale_message.location }}:
                    </v-card-title>
                    <v-card-subtitle>{{ locale_message.location_name }}</v-card-subtitle>

                    <v-card-title>
                        <v-icon>mdi-calendar-week-begin</v-icon>
                        {{ locale_message.beginning }}:
                    </v-card-title>
                    <v-card-subtitle>{{ locale_message.beginning_date }}</v-card-subtitle>

                </v-card>
                <p></p>
                <v-card>
                    <v-card-title>
                        {{ locale_message.requisites }}
                    </v-card-title>
                    <v-divider></v-divider>

                    <v-card-subtitle class="pb-0">{{ locale_message.fund_name }}</v-card-subtitle>
                    <v-card-subtitle class="pb-0">{{ locale_message.account_short_number }}</v-card-subtitle>
                    <v-card-subtitle class="pb-0">{{ locale_message.account_number }}</v-card-subtitle>
                    <v-card-subtitle class="pb-0">{{ locale_message.MFO }}</v-card-subtitle>
                    <v-card-subtitle class="">{{ locale_message.EDRPOU }}</v-card-subtitle>
                </v-card>
                <v-card class="mt-4">
                    <v-btn
                        large
                        block
                        color="error"
                        @click="dialog = !dialog"
                        width="100"
                    >
                        <strong style="font-size: large; font-weight: 900; letter-spacing: 5px">{{ locale_message.support }}</strong>
                    </v-btn>
                    <!--                    <v-img src="/res/img/secure.png"></v-img>-->
                    <v-card-text>
                        <v-icon>mdi-information-outline</v-icon>
                        {{ locale_message.donate_pay_info }}
                    </v-card-text>
                </v-card>
                <v-dialog
                    v-model="show_loading"
                    hide-overlay
                    persistent
                    width="300"
                >
                    <v-card
                        color="primary"
                        dark
                    >
                        <v-card-text>
                            {{ locale_message.processing }}
                            <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col
                cols="12"
                md="7"
                lg="8"
            >
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            lg="6"
                        >
                            <v-img
                                :width="'100%'"
                                :alt="'marianna'"
                                :src="'/res/img/logo.jpg'"
                            ></v-img>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="6"
                        >
                            <v-card
                                id="video-main"
                                class="pa-1"
                            >
                                <!--                                <iframe-->
                                <!--                                    src="https://www.youtube.com/embed/Img0o46GP4A"-->
                                <!--                                    title="YouTube video player"-->
                                <!--                                    frameborder="0"-->
                                <!--                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                                <!--                                    allowfullscreen-->
                                <!--                                    style="width: 100%"-->
                                <!--                                    :height="height_video"-->
                                <!--                                ></iframe>-->
                                <video style="width: 100%" controls preload="auto" id="video-1">
                                    <source src="/res/video/video-1.mp4" type="video/mp4">
                                </video>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title>{{ locale_message.collection_description_title }}</v-card-title>
                                <v-divider></v-divider>
                                <v-card-subtitle>
                                    <strong>
                                        {{ locale_message.collection_description_text1 }}
                                    </strong>
                                    {{ locale_message.collection_description_text2 }}
                                    <br><br>
                                    {{ locale_message.collection_description_text3 }}
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <strong>{{ locale_message.collection_description_text4 }}</strong>
                                    {{ locale_message.collection_description_text5 }}
                                    <strong>{{ locale_message.collection_description_text6 }}</strong>
                                    {{ locale_message.collection_description_text7 }}
                                    <strong>{{ locale_message.collection_description_text8 }}</strong>
                                    <br><br>
                                    {{ locale_message.collection_description_text9 }}
                                    <strong>{{ locale_message.collection_description_text10 }}</strong>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <strong>{{ locale_message.collection_description_text11 }}
                                    </strong>
                                    <br><br>
                                    {{ locale_message.collection_description_text12 }}
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <strong>{{ locale_message.collection_description_text13 }}</strong>
                                    {{ locale_message.collection_description_text14 }}
                                    <strong>{{ locale_message.collection_description_text15 }}</strong>
                                    {{ locale_message.collection_description_text16 }}
                                    <br><br>
                                    {{ locale_message.collection_description_text17 }}
                                    <strong>{{ locale_message.collection_description_text18 }}</strong>
                                    <br><br>
                                    {{ locale_message.collection_description_text19 }}
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-card>
                                <v-card-title>{{ locale_message.documentations }}</v-card-title>
                                <v-divider></v-divider>
                                <v-container>
                                    <v-row>
                                        <v-col
                                            v-for="(v, k) in docs" :key="k"
                                            cols="12"
                                            md="6"
                                            lg="4"
                                        >
                                            <v-img
                                                :src="v.src"
                                                class="grey lighten-2 cursor-pointer"
                                                @click="showImg(v.src)"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-dialog
            v-model="modal_img"
            max-width="560px"
        >
            <template v-slot:default="modal_img">
                <v-icon class="d-flex justify-end" dark color="accent" @click="modal_img.value = false">mdi-close-box
                </v-icon>
                <v-img
                    :src="active_img"
                ></v-img>
            </template>
        </v-dialog>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
        >
            <v-card tile>
                <v-toolbar
                    flat
                    dark
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text
                    class="pa-0"
                >
                    <div id="liqpay_checkout"></div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from "axios"
import { mapGetters } from 'vuex'

export default {
    name: "Info",
    data() {
        return {
            height_video: 0,
            dialog: false,
            modal_img: false,
            show_loading: false,
            is_init: false,
            active_img: '',
            docs: [
                {
                    src: '/res/img/docs/doc-1.png'
                },
                {
                    src: '/res/img/docs/doc-2.png'
                },
                {
                    src: '/res/img/docs/doc-3.png'
                },
                {
                    src: '/res/img/docs/doc-4.png'
                },
                {
                    src: '/res/img/docs/doc-5.png'
                },
                {
                    src: '/res/img/docs/doc-6.png'
                },
                {
                    src: '/res/img/docs/doc-7.png'
                },
                {
                    src: '/res/img/docs/doc-8.png'
                },
                {
                    src: '/res/img/docs/doc-9.png'
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            locale_message: "lang_messages"
        }),
    },
    watch: {
        dialog(show) {
            if (show && !this.is_init) {
                this.show_loading = true;
                this.initLiqpay();
            }
        }
    },
    created() {
        this.calculateHeightMainVideo();
    },
    methods: {
        showImg(src) {
            this.active_img = src;
            this.modal_img = true;
        },
        calculateHeightMainVideo() {
            setInterval(() => {
                this.height_video = parseInt(document.getElementById('video-main')?.clientWidth * 56.25 / 100);
            }, 1000)
        },
        initLiqpay() {
            axios.get('/api/order/create', {
                params: {
                    locale: this.$route.params.locale
                }
            }).then(({data}) => {
                // console.log(data);
                let lang;
                switch (this.$route.params.locale) {
                    case 'ua':
                        lang = 'uk';
                        break;
                    case 'en':
                        lang = 'en';
                        break;
                    case 'ru':
                        lang = 'ru';
                        break;
                    default:
                        lang = 'en'
                }
                //eslint-disable-next-line no-undef
                LiqPayCheckout.init({
                    data: data.data,
                    signature: data.signature,
                    embedTo: "#liqpay_checkout",
                    language: lang,
                    mode: "embed" // embed || popup
                }).on("liqpay.callback", (data) => {
                    console.log(data.status);
                    console.log(data);
                    console.log('CALLBACK')
                    // eslint-disable-next-line no-unused-vars
                }).on("liqpay.ready", (data) => {
                    console.log('READY')
                    this.is_init = true;
                    this.show_loading = false;
                    // ready
                    // eslint-disable-next-line no-unused-vars
                }).on("liqpay.close", (data) => {
                    console.log('CLOSE')
                    this.show_loading = false;
                    // close
                });
            })
        }
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">

.found_amount_price {
    color: #e91e63;
    font-size: 1.2rem;
}

.amount_price {
    color: #4caf50;
    font-size: 1.2rem;
}

</style>
