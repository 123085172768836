import { mapGetters, mapMutations } from 'vuex';
import { UA, EN, RU, GALLERY, DOCUMENTS, INFO }             from "@/storage/shared/constants";

export default {
    computed: {
        ...mapGetters({
            lang_messages: 'lang_messages',
            locale: 'locale',
            page: 'page',
            isInfo: 'isInfo',
            isGallery: 'isGallery',
            isDocuments: 'isDocuments',
        }),
        ua: () => UA,
        en: () => EN,
        ru: () => RU,
        info: () => INFO,
        gallery: () => GALLERY,
        documents: () => DOCUMENTS,
    },
    data() {
        return {
            isUA: false,
            isEN: false,
            isRU: false,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isUA = this.locale === UA;
            this.isEN = this.locale === EN;
            this.isRU = this.locale === RU;
            this.SET_IS_GALLERY(this.page === GALLERY);
            this.SET_IS_DOCUMENTS(this.page === DOCUMENTS);
            this.SET_IS_INFO(this.page === INFO);
        }, 100)
    },
    methods: {
        ...mapMutations(['SET_IS_INFO', 'SET_IS_DOCUMENTS', 'SET_IS_GALLERY'])
    },
}
