<template>
    <v-app>

        <Header/>
        <router-view/>
        <Footer/>
    </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigate       from "@/Mixins/Navigate";
import Header         from "./components/Header/Header";
import Footer         from "./components/Footer";

export default {
    name: "App",
    components: {Footer, Header},
    mixins: [
        Navigate
    ],
    computed: {
        ...mapGetters({
            locale_message: "lang_messages"
        })
    },
    data() {
        return {
            show: true,
        }
    },
    created() {
    },
    methods: {},
}
</script>

<style scoped>


</style>
