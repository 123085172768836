import Vue  from 'vue'
import Vuex from 'vuex'

import axios  from 'axios'
import { UA } from "@/storage/shared/constants";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        lang_messages: {},
        locale: UA,
        page: '',
        isInfo: false,
        isGallery: false,
        isDocuments: false,
    },
    getters: {
        lang_messages: state => state.lang_messages,
        locale: state => state.locale,
        page: state => state.page,
        isInfo: state => state.isInfo,
        isGallery: state => state.isGallery,
        isDocuments: state => state.isDocuments,
    },
    mutations: {
        SET_LANG_MESSAGES(state, payload) {
            state.lang_messages = payload;
        },
        SET_LOCALE(state, value) {
            state.locale = value;
        },
        SET_PAGE(state, value) {
            state.page = value;
        },
        SET_IS_INFO(state, value) {
            state.isInfo = value;
        },
        SET_IS_GALLERY(state, value) {
            state.isGallery = value;
        },
        SET_IS_DOCUMENTS(state, value) {
            state.isDocuments = value;
        },
    },
    actions: {
        fetchLangMessages({commit}, locale) {
            axios.get(`/api/lang/${locale}`)
                .then(({data}) => commit('SET_LANG_MESSAGES', data))
        },
    }
})
