import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const themeLight = {
    primary: '#37a423',
    secondary: '#3f51b5',
    accent: '#c01414',
    error: '#e91e63',
    warning: '#ff9800',
    info: '#00bcd4',
    success: '#4caf50'
}

const opts = {
    breakpoint: { mobileBreakpoint: 960 },
    theme: {
        dark: false,
        themes: {
            light: themeLight,
        },
    },
}

export default new Vuetify(opts);
