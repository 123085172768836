import Vue       from 'vue'
import VueRouter from 'vue-router'

import Main      from "@/components/Main";
import Info      from "@/components/Info";

Vue.use(VueRouter)

const routes = [
    {
        path: '/:locale',
        name: 'Main',
        component: Main,
        children: [
            {
                path: ':page(info)',
                name: 'Info',
                component: Info
            },
        ]

    }
]

export default new VueRouter({
    mode: 'history',
    routes
})
