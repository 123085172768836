<template>
    <v-card
        class="d-block d-sm-flex justify-start"
        color="primary"
        flat
        tile
        dark
    >
        <v-card-text>
            {{ locale_message.fund_name }}
            <p class="mb-0">{{ locale_message.account_short_number }}</p>
            <p class="mb-0">{{ locale_message.MFO }}</p>
            <p class="mb-0">{{ locale_message.EDRPOU }}</p>
            <p class="mb-0">{{ locale_message.account_number }}</p>
        </v-card-text>
        <v-card-text>
            <strong>{{ locale_message.localtion_fund_name }}</strong>
            <p class="mb-0">{{ locale_message.localtion_fund_1 }}</p>
            <p class="mb-0">{{ locale_message.localtion_fund_2 }}</p>
            <p class="mb-0"><a class="text-decoration-none" style="color: hsla(0,0%,100%,.7)" href="tel:+380633656715">{{ locale_message.localtion_fund_phone }}</a></p>
            <p class="mb-0"><a class="text-decoration-none" style="color: hsla(0,0%,100%,.7)" href="mailto:m0633656715@gmail.com">e-mail: {{ locale_message.localtion_fund_email }}</a></p>
        </v-card-text>
        <v-card-subtitle
            class="d-flex align-content-center"
        >
            <v-row>
                <v-col
                    class="d-flex justify-center flex-sm-column"
                >
                    <v-btn
                        color="secondary"
                        class="mr-2 mr-sm-0 mb-0 mb-sm-2"
                        href="https://www.facebook.com/SaveMariannaSMA"
                        target="_blank"
                    >
                        <v-icon>mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn
                        color="hsl(304,77%,41%)"
                        href="https://www.instagram.com/save_marianna_sma"
                        target="_blank"
                    >
                        <v-icon>mdi-instagram</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-subtitle>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Footer",
    computed: {
        ...mapGetters({
            locale_message: "lang_messages"
        }),
    },
}
</script>

<style scoped>

</style>
