import Vue     from 'vue'
import App     from './App.vue'
import vuetify from '@/plugins/vuetify'
import store   from '@/storage'
import router  from '@/routes'

//CSS
import "@/style/app.scss"

Vue.config.productionTip = false

const _vue = new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')

window._vue = _vue;
